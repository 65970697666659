// import {useEffect} from 'react'
import React from 'react'
import './Slider.css'

function Slider() {
    var time = 5;
    var which = 0;
    var selected = false;

    const next = () => {
        var slide = document.getElementById("slide");
        var b1 = document.getElementById("b1");
        var b2 = document.getElementById("b2");
        var b3 = document.getElementById("b3");
        slide.style.transition = "left linear 1s";
        if(selected === false)
        {
            which++;
        }
        if( which > 2)
        {
            which=0;
        } 
        slide.style.left = -100 * which + "vw"; 
        switch(which)
        {
            case 1:
                b1.classList.remove('selected');
                b2.classList.add('selected');
                b3.classList.remove('selected');
            break;
            case 2:
                b1.classList.remove('selected');
                b2.classList.remove('selected');
                b3.classList.add('selected');
            break;
            default:
                b1.classList.add('selected');
                b2.classList.remove('selected');
                b3.classList.remove('selected');
            break;
        } 
        if(selected)
        {
            setTimeout(()=>{ 
                selected = false;
            }, time*1000);
        }
        setTimeout(()=>{slide.style.transition = "none";},1000)
    }

    const change_slide = (x) => {
        if(x<=0){
            if(x===0){
                which++;
                if(which>2){
                    which -= 3;
                }
            }
            else{
                which--;
                if(which<0){
                    which += 3;
                }
            }
        }
        else{
            which = x-1; 
        }
        selected = true;
        next();
    }

    // setInterval(next, time * 1000);
    
    return (
        <>
        <div id="slider">
            <div id="slide">
                <div className="slide s1"></div>
                <div className="slide s2"></div>
                <div className="slide s3"></div>
            </div>
            <div className="slider_arrow_right">
                <div id="slider_arrow" onClick={()=>{change_slide(0)}}></div>
                <div className="arrow slider right"></div>
            </div>
            <div className="slider_arrow_left">
                <div id="slider_arrow" onClick={()=>{change_slide(-1)}}></div>
                <div className="arrow slider left"></div>
            </div>
            <div id="bullets">
                <div id="b1" className="bullet selected" onClick={()=>{change_slide(1)}}></div>
                <div id="b2" className="bullet" onClick={()=>{change_slide(2)}}></div>
                <div id="b3" className="bullet" onClick={()=>{change_slide(3)}}></div>
            </div>
        </div>
        </>
    )
}

export default Slider