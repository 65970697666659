import React from 'react';
import { useLocation  } from 'react-router-dom';
import './GoUp.css';

function GoUp() {
    // eslint-disable-next-line
    const location = useLocation();
    const goTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    goTop();
    const onScroll = () => {
        var sWidth = window.innerWidth;
        var top_btn = document.getElementById("btn");

        if(sWidth >= 500)
        {
            var vh = window.innerHeight * 0.01;
            
            if(window.scrollY > (24 * vh))
            {
                top_btn.style.bottom = 6 + "vh";
            }
            else if(window.scrollY < (24 * vh))
            {
                top_btn.style.bottom = -20 + "vh"
            }
        }
        else
        {
            top_btn.style.bottom = -20 + "vh"
        }
    }

    window.addEventListener('resize', onScroll);
    window.addEventListener('scroll', onScroll);
    window.addEventListener('load', goTop);

    return (
        <>
        <div id="btn" className="top" onClick={goTop}>
            <div className="arrow_up"></div>
        </div>
        </>
    )
}

export default GoUp