import React from 'react';
import parse from 'html-react-parser';
import { Link, useLocation  } from 'react-router-dom';
import { ID, messageEN, messagePL } from '../../Message';
import './Footer.css';

function Footer() {
    // eslint-disable-next-line
    const location = useLocation();
    let message = '';
    
    if (global.language === 'pl') {
        message = messagePL.footer; 
    } else {
        message = messageEN.footer;
    } 

    return (
        <>
        <footer>
            <div className="text">
                <span><Link to="/contact">{parse(message[0])}</Link></span>
            </div>
            <div className="content">
                <div className="contact">
                    <p>
                        <b>{parse(message[1])}</b>
                        {parse(ID.email)}
                    </p>
                    <p>
                        <b>{parse(message[2])}</b>
                        {parse(ID.phone)}
                    </p>
                    <p className='last'>
                        <b>{parse(message[3])}</b>
                        {parse(ID.address)}
                    </p>
                </div>
                <div className="map">
                    <a className='map' href="https://goo.gl/maps/fZaGYsfCowgA4tGSA">
                        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d540.8852280413462!2d23.19980924352045!3d53.12391839999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471fff292df1e7a9%3A0xbf4ce4c384aefff1!2sProfesjonalne%20lekcje%20rysunku%20i%20malarstwa.%20Portrety%2C%20obrazy%20do%20Twoich%20wn%C4%99trz%2C%20grafika%2C%20projektowanie.!5e1!3m2!1sen!2spl!4v1651594383517!5m2!1sen!2spl" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>      
                    </a>                           
                </div>    
            </div>
            <div className="author">
                <h6>
                    <b>© Monika Sienkiewicz 2022</b>
                    <p className="creator">Made with &#10084; by Radosław Sienkiewicz</p>
                </h6>
            </div>
        </footer>
        </>
    )
}

export default Footer