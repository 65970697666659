import React from "react";
// import { useLocation  } from 'react-router-dom';
// import { messageEN, messagePL } from '../../../Message';
import "./Reviews.css";

function Reviews() {
  /*const location = useLocation();
    let message = '';

    if (global.language === 'pl') {
        message = messagePL.contact; 
    } else {
        message = messageEN.contact;
    } 
    */
  return (
    <>
      <div className="center">
        <div className="card">
          <p className="name">Joanna Borowiec</p>
          <div className="stars">
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
          </div>
          <p className="text">
          Gdyby była możliwość oceniania lekcji większą ilością gwiazdek- na pewno byłaby to "droga mleczna". Pani Monika bardzo profesjonalnie podchodzi do prowadzonych przez nią zajęć, które charakteryzują się niezwykle urozmaiconym programem- adekwatnym zarówno do wieku, umiejętności, jak i potrzeb uczestnika. Lekcje przeplatane są wiadomościami zarówno praktycznymi, jak i teoretycznymi z dziedziny kierunków malarstwa, sztuki. Pani Monika jest osobą niezwykle ciepłą, przyjaźnie nastawioną do uczestnika zajęć. Jest szczera zarówno w krytyce, jak i pochwałach w stosunku do osiąganych umiejętności, widocznych z lekcji na lekcje. Z wielkim entuzjazmem polecam z córką przygotowującą się do egzaminów do Liceum Plastycznego lekcje u Pani Moniki, ich niezwykłą atmosferę i profesjonalizm.
          </p>
        </div>
        <div className="card">
          <p className="name">Katarzyna Gierasimiuk</p>
          <div className="stars">
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
          </div>
          <p className="text">
            Polecam wszystkim, małym i dużym. Pani Monika to najlepszy
            nauczyciel jakiego spotkałam, a spotkałam ich wielu jestem z
            wykształcenia plastykiem. Obie moje córki przygotowywały się do
            egzaminów do liceum plastycznego w pracowni pani Moniki, teraz są
            uczennicami tego liceum , kontynuują naukę u pani Moniki Sienkiewicz
            i wspaniałe się rozwijają. Jest wielu artystów plastyków, ale
            dobrych nauczycieli w tej dziedzinie jak na lekarstwo. Pani Monika
            należy do tej nielicznej grupy świetnych nauczycieli.
          </p>
        </div>
        <div className="card">
          <p className="name">Jarek Ninkiewicz</p>
          <div className="stars">
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
          </div>
          <p className="text">
          Miło jest pobierać naukę od tak przemiłej i profesjonalnej Pani  Moniki, 
          gdzie można odkryć swój talent ale także dzięki profesjonalnej nauce wiedzieć jak rysować czy malować.
          <br></br>Wielki szacunek dla nauki u Pani Moniki. Dziękuję i polecam🌹🌹🌹</p>
        </div>
        <div className="card">
          <p className="name">Grażyna Konecko</p>
          <div className="stars">
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
          </div>
          <p className="text">
            Polecam wszystkim. Perfekcja. Uczeń w rękach mistrza z każdą lekcja
            staje się doskonalszy w swych pracach. Postepy prac są sukcesem na
            wystawach. To Pani Monika dostosowuje się do indywidualnych potrzeb
            ucznia.
          </p>
        </div>
        <div className="card">
          <p className="name">Katarzyna Komarnicka</p>
          <div className="stars">
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
          </div>
          <p className="text">
            Pani Monika jest profesjonalistką w tym co robi. Potrafi przekazać
            ogrom wiedzy w lekki, przyjemny sposób. Jest osobą otwarta, łatwo
            nawiązała kontakt z dzieckiem, ktore wychodzi z zajęć przepełnione
            radością i pozytywną energią. Niezwykle miło jest patrzeć jak pod
            fachowym okiem eksperta pociecha rozwija swoje pasje. Prace
            powstające na zajęciach zawsze wywoluja u mnie pozytywne
            zaskoczenie. Jako rodzic bardzo cenię sobie również bezproblemowy
            kontakt z nauczycielem oraz rzeczowe informacje zwrotne na temat
            postępów dziecka.
          </p>
        </div>
        <div className="card">
          <p className="name">Magdalena Ławrynowicz</p>
          <div className="stars">
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
          </div>
          <p className="text">
            Chciałam się podzielić z państwem wspaniałą wiadomością a mianowicie
            moja córka dzięki lekcjom rysunku i malarstwa u pani Moniki
            Sienkiewicz dostała się do liceum plastycznego w Supraślu. Dzięki
            dużemu doświadczeniu pani Moniki bez problemu poradziła sobie na
            egzaminie z rzeźby malarstwa i rysunku. Bardzo polecam zajęcia u
            pani Moniki i jeszcze raz bardzo dziękujemy za czas i profesjonalizm
            na lekcjach rysunku.
          </p>
        </div>
        <div className="card">
          <p className="name">Ja Jarsen</p>
          <div className="stars">
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
          </div>
          <p className="text">
            Genialny nauczyciel! Mądry, z dużą wiedzą i świetnym podejściem do
            ucznia. Polecam. Inne szkoły rysunku i malarstwa nie dorównują tym
            lekcjom.
          </p>
        </div>
        <div className="card">
          <p className="name">Megi Megi</p>
          <div className="stars">
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
          </div>
          <p className="text">
            Bardzo profesjonalne i indywidualne podejście do ucznia na zajęciach
            u pani Moniki. Świetnie szkoli w zakresie rysunku malarstwa i
            rzeźby. Córka zawsze wraca z zajęć zadowolona i bogatsza o
            doświadczenia plastyczne.Szczerze polecamy i zachęcamy do zajęć u
            pani Moniki.
          </p>
        </div>
        <div className="card">
          <p className="name">Monika Babicz</p>
          <div className="stars">
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
          </div>
          <p className="text">
            Lekcje z Panią Moniką to cudowne chwile pozwalające oderwać się od
            rzeczywistości. Pod jej okiem, w miłym i spokojnym otoczeniu
            powstają małe dzieła sztuki. Z całego serca polecam zajęcia każdemu,
            kto nie boi się spełniać swoich marzeń o tworzeniu i rozwijaniu
            pasji artystycznej. Pani Monika wie jak pokierować uczniem aby z
            zajęć wyniósł nie tylko wiedzę praktyczną ale i teoretyczną.
          </p>
        </div>
      </div>
    </>
  );
}

export default Reviews;
