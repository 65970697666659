import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { messageEN, messagePL } from '../../Message';
import './Motto.css';


function Motto(){
    const location = useLocation();

    useEffect(() => {
        let message = '';
        if (global.language === 'pl') {
            message = messagePL.motto; 
        } else {
            message = messageEN.motto;
        } 

        var mottoText = document.getElementById("motto_text");
        switch(location.pathname)
        {
            case "/":
                mottoText.innerHTML = message[0];
            break;
            case "/lessons":
                mottoText.innerHTML = message[1];
            break;
            case "/gallery":
                mottoText.innerHTML = message[2];
            break;
            case "/contact":
                mottoText.innerHTML = message[3];
            break;
            case "/reviews":
                mottoText.innerHTML = message[4];
            break;
            default:
                try {
                    const err = new Error('Not Found');
                    err.status = 404;
                    throw err;
                }
                catch(err) {
                    mottoText.innerHTML = message[4];
                    console.error("404 error Page Not Found");
                }
                
            break;
        }
        onScroll();
    }, [location]);

    const onScroll = () =>
    {
        var mottoImg = document.getElementById("motto_image");
        var mottoText = document.getElementById("motto_text");
        var mottoText_rect = mottoText.getBoundingClientRect();
        var height = window.innerHeight;
        var offset = 100;
        if(mottoText_rect.top < height + offset)
        {
            var percentage = window.scrollY / 10;
            if (percentage > 100) percentage = 100;
            mottoImg.style.bottom = -1 * percentage + "%";
        }   
    }

    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onScroll);

    return (
        <>
            <div className="motto">
                <h1 id="motto_text" className="text">Wspaniała&nbsp; Sztuka</h1>
                <div id="motto_image"></div>
            </div>
        </>  
    );
};

export default Motto;