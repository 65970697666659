import React, { useEffect }  from 'react';
import parse from 'html-react-parser';
import { useLocation  } from 'react-router-dom';
import { messageEN, messagePL } from '../../../Message';
import './Contact.css';

function Contact() {
    const location = useLocation();
    let message = '';
    let contact_text = '';

    if (global.language === 'pl') {
        message = messagePL.contact; 
    } else {
        message = messageEN.contact;
    } 

    if(location.pathname === "/contact") {
        contact_text = '<article id="text" className="first"><div><h4>'+message[12]+'</h4></div></article>';
        console.log(contact_text);
    } else {
        contact_text = '';
    }

    useEffect(() => {
        clearMessages();
    }, [location]);

    const validateForm = () => {
        var error = false;
        const nameInput = document.querySelector("#name");
        const email = document.querySelector("#email");
        const mess = document.querySelector("#message");
        const success = document.querySelector("#success");
        const errorNodes = document.querySelectorAll(".error");

        clearMessages();
        if(nameInput.value.length < 1)
        {
            errorNodes[0].innerText = message[6];
            nameInput.classList.add("error-border");
            error = true;
        }
        if(email.value.length < 1)
        {
            errorNodes[1].innerText = message[7];
            email.classList.add("error-border");
            error = true;
        }
        else if(!emailIsValid(email.value))
        {
            errorNodes[1].innerText = message[8];
            email.classList.add("error-border");
            error = true;
        }
        if(mess.value.length < 1)
        {
            errorNodes[2].innerText = message[9];
            mess.classList.add("error-border");
            error = true;
        }
        if(!error)
        {         

            success.innerText = message[10];
            success.style.display = 'block';
            let form = document.createElement('form');
            form.action = 'https://formsubmit.co/plastmoni@gmail.com';
            form.method = 'POST';
            form.id = "fake_form";
            form.innerHTML = '<input type="text" name="name" value="'+ nameInput.value + ": " + mess.value + '" required> <input type="email" name="email" value="' + email.value + '" required>';
            document.body.append(form);
            form.submit();
        }
    }
    
    const clearMessages = () => {
        const nameInput = document.querySelector("#name");
        const email = document.querySelector("#email");
        const mess = document.querySelector("#message");
        const success = document.querySelector("#success");
        const errorNodes = document.querySelectorAll(".error");

        // document.getElementById("success").style.display = "block";
        for(var i = 0; i < errorNodes.length; i++)
        {
            errorNodes[i].innerText = "";
        }
        nameInput.classList.remove("error-border");
        email.classList.remove("error-border");
        mess.classList.remove("error-border");
        success.innerText = "";
        success.style.display = 'none';
    }
    const emailIsValid = (email) =>{
        let pattern = /\S+@\S+\.\S+/;
        return pattern.test(email);
    }

    return (
        <>
            {parse(contact_text)}
            <div className="form">
                {/* <span>Zachęcam do kontaktu</span> */}
                <div id="email_form" >
                    <label htmlFor="name">{message[0]}</label>
                    <input type="text" id="name" placeholder={message[1]}/>
                    <div className="error"></div>
                    <label htmlFor="email">{message[2]}</label>
                    <input type="text" id="email" placeholder={message[3]}/>
                    <div className="error"></div>
                    <label htmlFor="message">{message[4]}</label>
                    <textarea id="message" placeholder={message[5]} rows="6"></textarea>
                    <div className="error"></div>
                    <div className="center">
                        <button onClick={validateForm}>{message[11]}</button>
                        <p id="success"></p>
                    </div>  
                </div>
            </div>
            {/* {clearMessages} */}
        </>  
    );
};

export default Contact;