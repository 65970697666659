import React, {useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { Link, useLocation  } from 'react-router-dom';
import { messageEN, messagePL } from '../../Message';
import './Navbar.css';


function Navbar() {
    const [menu, setMenu] = useState(() => {return true})
    const location = useLocation();
    let message = '';
    
    if (global.language === 'pl') {
        message = messagePL.navbar; 
    } else {
        message = messageEN.navbar;
    } 

    useEffect(() => {
        var bars = document.getElementById("bars");
        var side_menu = document.getElementById("side_menu");
        bars.classList.remove("change");
        side_menu.style.transform = "translate(-100%,0)";
        setMenu(true);
    }, [location]);

    const changeMenu = () => {
        setMenu(!menu)
        console.log(menu)
        var bars = document.getElementById("bars");
        var side_menu = document.getElementById("side_menu");
        if(menu){
            bars.classList.add("change");
            side_menu.style.transform = "translate(0,0)";
        } else {
            bars.classList.remove("change");
            side_menu.style.transform = "translate(-100%,0)";
        }
    };

    const changeLanguage = (lang) => {
        // global.language = lang;
    };

    return (
        <>
        <div id="side_menu">
            <Link to="/">{parse(message[0])}</Link>
            <Link to="/lessons">{parse(message[1])}</Link>
            {/* <Link to="/gallery">{parse(message[2])}</Link> */}
            <Link to="/reviews">{parse(message[3])}</Link>
            <Link to="/contact">{parse(message[4])}</Link>
        </div>
        <header>
            <nav>
                <section id="bars">
                    <div id="menu" onClick={changeMenu}>
                        <div className="bar1"></div>
                        <div className="bar2"></div>
                        <div className="bar3"></div>
                    </div>
                </section>
                <section id="logo">
                    <div className="logo_img"></div>
                </section>
                <section id="options">
                    <div id="large_menu">
                    <Link to="/">{parse(message[0])}</Link>
                    <Link to="/lessons">{parse(message[1])}</Link>
                    {/* <Link to="/gallery">{parse(message[2])}</Link> */}
                    <Link to="/reviews">{parse(message[3])}</Link>
                    <Link to="/contact">{parse(message[4])}</Link>
                    </div>
                </section>
                <section>
                    <div id="languages">
                        <Link to={location.pathname}>
                            <div onClick={() => changeLanguage("pl")}>
                                <p><span id="polish">polski</span></p>
                                <img loading="eager" src="img/icons/pl_PL.png" alt="pl_PL.png" title="polski"/>
                            </div>
                        </Link>
                        {/* <Link to={location.pathname}>
                            <div onClick={() => changeLanguage("en")}>
                                <p><span id="english">english</span></p>
                                <img loading="eager" src="img/icons/en_US.png" alt="en_US.png" title="english"/>
                            </div>
                        </Link> */}
                    </div>
                </section>
            </nav>
        </header>
        </>
    )
}

export default Navbar