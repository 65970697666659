import React from 'react';
// import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import axios from 'axios';

import Navbar from './components/Navbar/Navbar';
import Slider from './components/Content/Slider/Slider';
import Motto from './components/Motto/Motto';
import Content from './components/Content/Articles/Articles';
//import Gallery from './components/Content/Gallery/Gallery';
//import GallerySlider from './components/Content/GallerySlider/GallerySlider';
import Lessons from './components/Content/Lessons/Lessons';
import Reviews from './components/Content/Reviews/Reviews';
import Contact from './components/Content/Contact/Contact';
import Footer from './components/Footer/Footer';
import GoUp from './components/GoUp/GoUp';
import './App.css';

function App() {

  // const getGeoInfo = () => {
  //   axios
  //     .get("https://ipapi.co/json/")
  //     .then((response) => {
  //       let data = response.data;
  //       if(data.country_name.toLowerCase() === "poland") {
  //         global.language = "pl";
  //       } else {
  //         global.language = "en";
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   getGeoInfo();
  // }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar/>
          <Routes>
            <Route 
              path='*'
              element={
                <>
                  <Motto/>
                </>
              }
              status={404}
            />
            <Route 
              path="/"
              element={
                <>
                  <Slider/>
                  <Motto/>
                  <Content/>
                  <GoUp/>
                  <Footer/>
                </>
              }
            />
            {/* <Route 
              path="/gallery"
              element={
              <>
                <GallerySlider/>
                <Motto/>
                <Gallery/>
                <GoUp/>
              </>
              }
            /> */}

            <Route 
              path="/lessons"
              element={
              <>
                <Motto/>
                <Lessons/>
                <GoUp/>
                <Footer/>
              </>
              }
            />
            <Route 
              path="/contact"
              element={
              <>
                <Motto/>
                <Contact/>
                <GoUp/>
                <Footer/>
              </>
              }
            />
             <Route 
              path="/reviews"
              element={
              <>
                <Motto/>
                <Reviews/>
                <GoUp/>
                <Footer/>
              </>
              }
            />
          </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
