import React from 'react';
import parse from 'html-react-parser';
import { useLocation  } from 'react-router-dom';
import { messagePL, messageEN } from '../../../Message';
import './Lessons.css';

function Lessons(){
    // eslint-disable-next-line
    const location = useLocation();
    let message = '';
    
    if (global.language === 'pl') {
        message = messagePL.lessons; 
    } else {
        message = messageEN.lessons;
        } 

    return (
        <>
        <div id="lekcje">
        <article className="lekcje">
            <div className="oferta">
                <div className="content"> 
                    <div className="oferty">
                            <span className='red'>{parse(message[0])}</span>
                            <p className="toLeft">{parse(message[1])}</p>
                            <div className="about_me mobile">
                                <div className="image" alt="Monika Sienkiewicz" title="Monika Sienkiewicz"></div>
                                <div className="text">
                                    <span className="imie red">{parse(message[2])}</span><br/>
                                    <p className='last'>{parse(message[3])}</p>
                                </div>
                            </div>
                            <span id="1">{parse(message[4])}</span>
                            <p>{parse(message[5])}</p> 
                            <span id="2">{parse(message[6])}</span>
                            <p>{parse(message[7])}</p> 
                            <span id="3">{parse(message[8])}</span>
                            <p>{parse(message[9])}</p> 
                            <span id="4">{parse(message[10])}</span>
                            <p>{parse(message[11])}</p> 
                            <span id="5">{parse(message[12])}</span>
                            <p>{parse(message[13])}</p> 
                            <span id="6">{parse(message[14])}</span>
                            <p>{parse(message[15])}</p> 
                            <span id="7">{parse(message[16])}</span>
                            <p className='last'>{parse(message[17])}</p> 
                    </div>
                </div>
                <div className="about_me desktop">
                    <div className="image" alt="Monika Sienkiewicz" title="Monika Sienkiewicz"></div>
                    <div className="text">
                        <span className="imie red">{parse(message[2])}</span><br/>
                        <p className='last'>{parse(message[3])}</p>
                    </div>
                </div>
            </div>
        </article>
        </div>
        </>
    );
};

export default Lessons;