global.language = 'pl';

export const messagePL = {
    navbar: [
        'strona główna', 
        'lekcje',
        'galeria', 
        'opinie',
        'kontakt'
    ],
    motto: [
        'Wspaniała&nbsp; Sztuka',
        'Lekcje Rysunku<br/>Malarstwa',
        'Galeria prac<br/>Moniki Sienkiewicz',
        'Kontakt',
        'Opinie na temat zajęć',
        '404 error',
    ],
    articles: [
        '#Galeria prac Moniki Sienkiewicz',
        'W procesie tworzenia kieruję się doborem środków wyrazu plastycznego w celu jak najpełniejszego oddania koncepcji dzieła. Kształtuję twórczą wizję inspirującego świata, który zaciekawia. Moje prace malarskie, dzięki szerokiej palecie barw, dają zróżnicowane możliwości aranżacji wnętrz. Obrazy nadają charakteru wystrojowi pomieszczeń. Przyciągają uwagę, zapraszają do kontemplacji malarskich kompozycji i przestrzeni. Urzekają „kolorami nastroju”, interpretacją uczuć i kolorystyką. Przedstawiona w nich twórcza wizja artystycznego świata zachwyca oraz wprowadza w dobry nastrój, pozwalając odprężyć się i zrelaksować.',
        '#Lekcje Rysunku i Malarstwa',
        'Jestem absolwentką Akademii Sztuk Pięknych w Łodzi, Wydziału Grafiki i Malarstwa. Od przeszło 20 lat prowadzę indywidualne kursy rysunku i malarstwa, działalność artystyczną oraz edukacyjną. Rozwijam pasje młodych ludzi, pomagam w kształtowaniu dalszej drogi twórczej. Z powodzeniem przygotowuję do egzaminów na: Akademię Sztuk Pięknych, Architekturę, Państwowego Liceum Sztuk Plastycznych. Wspieram, nauczam warsztatu pracy, prezentuję technikę oraz sposoby jej realizacji, udzielam wielu rad i korekt. Czynione postępy umiejętności rysunkowych i malarskich są widoczne już w trakcie pierwszych zajęć. Zapraszam na lekcje.',
        'Lekcje<br/>Rysunku<br/>i Malarstwa',
        'Opinie<br/>na temat<br/>Zajęć',
        'Jeżeli jesteś zainteresowana / zainteresowany kupnem obrazu lub chcesz zapisać się na prywatne lekcje, skontaktuj się ze mną -> ',
        'KONTAKT',
        'W przypadku pytań wyślij wiadomość.'
    ],
    contact: [
        'Imię:',
        'Wpisz swoje imię',
        'Email: ',
        'Podaj adres email',
        'Wiadomość: ',
        'Treść wiadomości',
        'Prosze podać imię',
        'Prosze podać email',
        'Email jest niepoprawny',
        'Wiadomość jest pusta',
        'Pomyślnie wysłano wiadomość',
        'Wyślij',
        'Jeżeli jesteś zainteresowana / zainteresowany kupnem obrazu lub chcesz zapisać się na prywatne lekcje, skontaktuj się ze mną pod numerem telefonu: +48 730 425 853. W przypadku pytań wyślij wiadomość.'
    ],
    gallery: [
        '#Prezentuję  kolekcję obrazów',
        '#Prezentuję  kolekcję obrazów o niepowtarzalnym charakterze artystycznym. Twórcza wizja inspirującego świata dzięki ciekawej kolorystyce prac ożywi ściany i nada wyrazu wystrojowi wnętrz. Zróżnicowana tematyka pozwoli na dopasowanie obrazu do pomieszczeń zarówno: dziennych, wypoczynkowych, mieszkalnych, dziecięcych, reprezentacyjnych jak i biurowych. Obrazy, na życzenie, zostaną zapakowane jako prezent na wyjatkową uroczystość. Będą wspaniałą niespodzianką, wywołując szczery uśmiech obdarowanej osoby.',
        'Jestes zainteresowany kupnem obrazu?<br/>Wybierz, który Ci się podoba i skontaktuj się ze mną -> ',
        'KONTAKT',
        'Jeżeli masz swój ulubiony motyw, który chcesz uwiecznić na papierze lub podobraziu malarski, nie ma problemu. Realizuję: pejzaże, portrety, martwe natury, prace rysunkowe i malarskie. Wykonuję projekty znaków graficznych, form przemysłowych.'
    ],
    gallerySlider: [
        "Tytuł: ",
        "Technika malarska: ",
        "Rozmiar: "
    ],
    lessons: [
        'Oferuję:',
        ' - przygotowanie do egzaminu do Państwowego Liceum Sztuk Plastycznych,<br/> - kształtowanie oraz doskonalenie umiejętności rysunku i malarstwa,<br/> - przygotowanie do egzaminu na uczelnie artystyczne,<br/> - poznanie oraz doskonalenie zróżnicowanych technik plastycznych a także tajników warsztatu pracy.',
        'Monika Sienkiewicz',
        'Cześć! Nazywam się Monika i od 20 lat zajmuję się nauczaniem języka artystycznego. Uwielbiam swoją pracę i kontakt z ludźmi. Ukończyłam z wyróżnieniem ASP w Łodzi na Wydziale Grafiki i Malarstwa. Wcześniej zaś Państwowe Liceum  Sztuk Plastycznych w Supraślu, w którym pracowałam po ukończeniu studiów jako nauczyciel przedmiotów artystycznych. Lubię śmiać się, poznawać nowe miejsca i podróżować. Jestem człowiekiem z pozytywną energią. Z przyjemnością dzielę się wiedzą bo nie ma większej przyjemności niż sukcesy moich uczniów w konkursach i na egzaminach. Ich satysfakcja oraz rozwój twórczy jest także moją radością.',
        '#Jak wyglądają zajęcia?',
        ' Zajęcia odbywają się w formie warsztatów plastycznych z elementami teorii. Rysunek i malarstwo realizujemy na brystolach, tekturze, podobraziu malarskim. Pracujemy przy sztalugach używając profesjonalnych narzędzi warsztatu plastyka. Większość ćwiczeń wykonujemy z natury. W czasie spotkań dowiesz się jak poprawnie zakomponować pracę, jak poradzić sobie z proporcjami ciała ludzkiego, ze światłocieniem. Poznasz teorię barw, nauczysz się stosować bogatą paletę barwną, spójną tonację, operować dominantą i akcentem kolorystycznym,  budować nastrój dzieła. Wyrobisz precyzję pracy. Ukształtujesz ekspresję twórczą. Skomponujesz prace do teczki wymaganej na uczelnię artystyczną. Przygotujesz się do egzaminu z rysunku, malarstwa i kompozycji.',
        '#A co jeśli chcę uczęszczać na zajęcia hobbystycznie?',
        'Wówczas poznajemy nowe techniki i narzędzia plastyczne, tak abyś mogła/mógł wybrać to co najbardziej Cię interesuje. Następnie "szlifujemy" Twoje pasje, umiejętności i warsztat pracy w wybranej specjalizacji. Mamy do wyboru: rysunek realistyczny, architektoniczny, portretowy, malarstwo pejzażowe, linoryt, projektowanie graficzne, malarstwo na jedwabiu, docoupage, techniki użytkowe i wiele, wiele innych.',
        '#Ile trwają zajęcia?',
        'Zajęcia trwają zwykle od 90 do 120 minut. Rozpoczynają się wprowadzeniem do tematu. Kolejnym krokiem są realizacje warsztatowe pod bacznym okiem artysty plastyka. Kończymy podsumowaniem i korektą kształtującą. Wskazane zostają mocne strony wykonanej pracy, dalszy kierunek rozwoju wraz z nakierowaniem na to, nad czym trzeba jeszcze popracować.',
        '#Jak często się spotykamy?',
        'Standardowo zajęcia odbywają się raz w tygodniu o umówionej, stałej porze. Przy moim systemie pracy z uczniem, popartym zagadnieniami do utrwalenia w domu, to zupełnie wystarczy. Jeżeli istnieje potrzeba częstszych spotkań, chociażby z powodu braku możliwości pracy w domu, możemy umówić się na zajęcia o większej częstotliwości.',
        '#Jaki jest koszt zajęć?',
        'Koszt zajęć zależy od ustaleń:<br/><span className="toLeft"> - kto zapewnia materiały potrzebne do wykonania prac,<br/> - w zakresie jakich technik plastycznych realizujemy program warsztatowy,<br/> - stopnia zaawansowania zajęć,<br/> - liczby uczestników (zwykle są to spotkania indywidualne lub 2 uczniów).<br/></span> Pierwsze, bezpłatne spotkanie poświęcamy na bliższe omówienie, poznanie, przegląd dotychczasowych prac (nie koniecznie wymagane) oraz ustalenie szczegółów.',
        '#Gdzie odbywają się zajęcia?',
        'Zajęcia odbywają się w pracowni plastycznej na terenie centrum Białegostoku. Adres oraz mapa w zakładce KONTAKT. Nie istnieje możliwość dojazdu do domu ucznia ani do wskazanego przez niego miejsca.',
        '#Jak wygląda odwołanie zajęć?',
        'Bardzo szanuję czas swój i ucznia. Dlatego warsztaty artystyczne wykorzystujemy w pełni na: rady, wskazówki, korekty i przede wszystkim na pracę. Przyjmujemy zasadę, iż odwołanie zajęć odbywa się nie później niż dzień przed spotkaniem, z 24 godzinnym wyprzedzeniem. Wyjątek stanowią sytuacje losowe. Wówczas zajęcia odpracowujemy w innym terminie.',

    ],
    footer: [
        'Kontakt',
        'email: ',
        'telefon nr: ',
        'adres: '
    ]
}

export const messageEN = {
    navbar: [
        'home', 
        'lessons',
        'gallery', 
        'contact'
    ],
    motto: [
        'Wonderful&nbsp; Art',
        'Drawing &<br/>Painting Lessons',
        'Gallery',
        'Contact',
        'Reviews',
        '404 error',
    ],
    articles: [
        '#Gallery',
        'In the process of creating I am guided by the choice of means of artistic expression in order to fulfill the convey of the work concept. I shape a creative vision of an interesting world. My paintings due to wide color range give various possibilities of interior design. They attract attention and invite you to contemplate painting compositions and spaces. They captivate with the "colors of the mood", feelings and impressions. The creative vision of the artistic world presented in them delights and puts you in good mood. They allow you to enjoy and relax.',
        '#Drawing & Painting Lessons',
        'I am a graduate of the Academy of Fine Arts in Łódź at the Graphics and Painting Department. I have been running artistic and educational activities for over 20 years. I develop young people\'s passions, teach and help in the process of shaping further creative path. I successfully prepare for exams at art academies and art high school. I encourage, support give a lot of advices and corrections. The time spent in front of the easel with a pencil and a brush in hand is the time of personal development of the student. The progress in drawing and painting skills is alread visible during the first classes. Try it yourself, I invite you to the lessons.',
        'Drawing<br/>Painting<br/>Lessons',
        'Gallery',
        'If you are interested, in buying a painting or want to sign up for private lessons, please contact me -> ',
        'CONTACT',
        'In case of questions please send me a message.'
    ],
    contact: [
        'Name:',
        'Write your name',
        'Email: ',
        'Write your email address',
        'Message: ',
        'Message content',
        'Please write your name',
        'Please write your email address',
        'Email is incorrect',
        'The message is empty',
        'The message was correctly sent',
        'Send',
        'If you are interested, in buying a painting or want to sign up for private lessons, please contact me. In case of questions please send me a message.'
    ],
    gallery: [
        'Are you interested in buying a painting?<br/>Choose which one you like and contact me -> ',
        'CONTACT',
        'If you have your favorite motive that you want to capture on paper or a painting canvas, no problem. I make: landscapes, portraits, still lifes, drawing and painting works. I design graphic signs and industrial forms.'
    ],
    gallerySlider: [
        "Title: ",
        "Painting technique: ",
        "Size: "
    ],
    lessons: [
        'I offer:',
        ' - preparation for the art universities exam,<br/> - preparation for the art high school exam,<br/> - shaping and improving drawing and painting skills,<br/> - learning and improving various art techniques as well as the secrets of the art workshop.',
        'Monika Sienkiewicz',
        'Hi! My name is Monika and I have been teaching artistic language for 20 years. I love my job and contact with people. I graduated with honors from the Academy of Fine Arts in Łódź at the Department of Graphics and Painting. Earlier, the Secondary School of Art in Supraśl, where I worked after graduating as an art teacher. I like to laugh, explore new places and travel. I am a person with positive energy. I am happy to share my knowledge because there is no greater pleasure than the success of my students in competitions and exams. Their satisfaction and creative development is also my joy.',
        '#What the classes are like?',
        'Classes are held in the form of art lessons with elements of theory. We make drawing and painting on bristol boards, cardboard, painting canvas. We work on easels using professional artistic workshop tools. We do most of the exercises from nature. During the meetings you will learn how to correctly arrange the work, how to deal with the proportions of the human body, with chiaroscuro. You will learn the theory of colors, learn to use a rich hue palette, a consistent tone, use a dominant and a color accent, and build the mood of a work. You will develop precision of work. You will shape creative expression. You will compose your works for the portfolio required for an art university. You will prepare for the drawing, painting and composition exam.',
        '#What if I would want to attend for classes hobbisticly ?',
        'Then we get to know new techniques and plastic tools so that you can choose what interests you the most. Then we "polish" your passions, skills and workshop in the selected specialization. We can choose from: realistic drawing, architectural drawing, portrait drawing, landscape painting, linocut, graphic design, silk painting, docoupage, applied techniques and many, many more.',
        '#How long are the classes?',
        'Classes usually last from 90 to 120 minutes. They begin with an introduction to the topic. Next step are workshop projects under the supervision of a fine artist . We end with a summary and a formative correction. Creating the work that can be done, doing the work, the direction of development along with the leads, what still needs to be improved.',
        '#How often do we meet?',
        'Ussualy, classes are held once a week at an agreed, fixed time. With my system of work with the student, supported by things to be improved at home, this is quite enough. If there is a need for more frequent meetings, for example due to the inability to work at home, we can arrange classes with a greater frequency.',
        '#What is the cost of the classes?',
        'The cost of the classes depends on the arrangements:<br/><span className = "toLeft"> - who provides the materials needed to perform the work,<br/> - in what art techniques we implement the classes program,<br/> - the level of advancement of the classes,<br/> - the number of participants (usually individual meetings or 2 students).<br/> </span> The first, free meeting is devoted to a closer discussion, getting to know, reviewing the work done so far (not necessarily required) and determining the details.',
        '#Where are the classes taking place?',
        'Classes are held in an art workshop in the center of Białystok. Address and map are in the CONTACT tab. It is not possible to travel to the student\'s home or to the place indicated by him.',
        '#How does the cancellation of classes look like?',
        'I respect my and the student\'s time very much. That is why we fully use the artistic classes for: advice, tips, corrections and, above all, for work. We accept the principle that the cancellation of classes takes place no later than the day before the meeting, with 24 hours in advance. Random situations are an exception. Then we work off the classes on a different date.',

    ],
    footer: [
        'Contact',
        'email: ',
        'telephone number: ',
        'address: '
    ]
}

export const ID = {
    email: 'plastmoni@gmail.com',
    phone: '+48 730 425 853',
    address: 'Zbożowa 26, Białystok'
}

const Message = {
    ID,
    messagePL,
    messageEN
}

export default Message;