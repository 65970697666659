import React from 'react';
import parse from 'html-react-parser';
import { Link, useLocation } from 'react-router-dom';
import { messageEN, messagePL } from '../../../Message';
import './Articles.css';

function Articles() {
    // eslint-disable-next-line
    const location = useLocation();
    let message = '';
    if (global.language === 'pl') {
        message = messagePL.articles; 
    } else {
        message = messageEN.articles;
    } 

    return (
        <>
            <div className="articles"> 
            <article id="text" className="under_motto">
                <div id="double">
                    <div className="leftText">
                    <h2>{parse(message[0])}</h2>
                        {/* <Link to="/gallery">
                            <h2>{parse(message[0])}</h2>
                        </Link> */}
                        <h3>{parse(message[1])}</h3>
                    </div>
                    <div className="rightText">
                        <Link to="/lessons">
                            <h2>{parse(message[2])}</h2>
                        </Link>
                        <h3>{parse(message[3])}</h3>
                    </div>
                </div>
            </article> 
            <article id="center">

                <div className="table">
                    <div className="row r1">
                        <div id="o2"></div>
                        <div id="o4">
                            <p>{parse(message[4])}</p>
                            <Link to='/lessons'>&nbsp;</Link>
                        </div>
                    </div>
                    <div className="row r2">
                        <div id="o1">
                            <p>{parse(message[5])}</p>
                            <Link to='/reviews'>&nbsp;</Link>
                        </div>
                        <div id="o3"></div>
                    </div>
                </div>
            </article>
            <article id="text" className='last'>
                <div>
                    <h4>
                        {parse(message[6])}
                        <Link to="/contact">
                            <span>{parse(message[7])}</span>
                        </Link>
                        <br/>
                        {parse(message[8])}
                    </h4>
                </div>
            </article>
            </div>
        </>
    )
}


export default Articles